<template>
  <div id="slider">
    <br>
        <img :src="currentImage" v-on:mouseover="stopRotation" v-on:mouseout="startRotation" alt="Keep smiling">
  </div>
</template>

<script>
import image1 from '../assets/sliderimages/pic1.jpg';
import image3 from '../assets/sliderimages/pic3.jpg';
import image4 from '../assets/sliderimages/pic4.jpg';
import image5 from '../assets/sliderimages/pic5.jpg';
import image6 from '../assets/sliderimages/pic6.jpg';
import image7 from '../assets/sliderimages/pic7.jpg';
import image8 from '../assets/sliderimages/pic8.jpg';
import image9 from '../assets/sliderimages/pic9.jpg';
import image10 from '../assets/sliderimages/pic10.jpg';
import image11 from '../assets/sliderimages/pic11.jpg';
import image12 from '../assets/sliderimages/pic12.jpg';
import image13 from '../assets/sliderimages/pic13.jpg';
import image14 from '../assets/sliderimages/pic14.jpg';
import image16 from '../assets/sliderimages/pic16.jpg';
import image17 from '../assets/sliderimages/pic17.jpg';
import image18 from '../assets/sliderimages/pic18.jpg';
import image20 from '../assets/sliderimages/pic20.jpg';
import image21 from '../assets/sliderimages/pic21.jpg';
export default {    
    data(){
        return {
            // images: ['http://i.imgur.com/vYdoAKu.jpg', 'http://i.imgur.com/PUD9HQL.jpg', 'http://i.imgur.com/Lfv18Sb.jpg', 'http://i.imgur.com/tmVJtna.jpg', 'http://i.imgur.com/ZfFAkWZ.jpg'],
            images: [image1, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image16,image17,image18,image20,image21],
            currentNumber: 0,
            timer: null
        }
    },    

    methods: {
        startRotation: function() {
            this.timer = setInterval(this.next, 3000);
        },

        stopRotation: function() {
            clearTimeout(this.timer);
            this.timer = null;
        },

        next: function() {
            this.currentNumber += 1
        },
        prev: function() {
            this.currentNumber -= 1
        }
    },
    
	computed: {
        currentImage: function() {
			const imgPath = this.images[Math.abs(this.currentNumber)%this.images.length];
            return imgPath;
        }
    },
    created: function () {
        this.startRotation();
    }
}
</script>
<style scoped>
	#slider{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 250px;
		height: 250px;
		margin-top: -125px; /* Half the height */
		margin-left: -125px; /* Half the width */
		vertical-align: center;
		text-align: center;
	}
    
</style>
